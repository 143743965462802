class routerHandle{

    /**
     * js代码中获取是否有权限
     * @param {string} action 权限标识
     * @param {number} plat 
     * @returns 
     */
    getTabs(action,plat){
        let btnRoute = JSON.parse(sessionStorage.getItem('__BTNPERM__')) || {}
		let btnPerm = []
        if( plat && plat == 1 ) return true
        if( btnRoute){
			btnPerm = [...btnRoute.web,...btnRoute.farm_admin]
		}

        if( !btnPerm.includes(action) ){
			console.log(action+':无权限');
			return false
		}
        return true
    }

    getCuurentTabs(page){
        let tabs = []
        switch(page){
            case "layout":
                if( this.getTabs("wxapp_set_custom_page",0) ) tabs.push({key:2,tab:'自定义页面'})
                if( this.getTabs("wxapp_set_theme",0) ) tabs.push({key:1,tab:'主题设置'})
                if( this.getTabs("wxapp_set_tabbar",0) ) tabs.push({key:4,tab:'底部tabbar'})
                if( this.getTabs("wxapp_set_user_center",0) ) tabs.push({key:5,tab:'个人中心'})
                break;
            case "basic-set":
                if( this.getTabs("wxapp_set_basic_farm",0) ) tabs.push({key:'basic',tab:'农场设置'})
                if( this.getTabs("wxapp_set_basic_weather",0) ) tabs.push({key:'weather',tab:'天气设置'})
                if( this.getTabs("wxapp_set_basic_score",0) ) tabs.push({key:'score',tab:'积分设置'})
                if( this.getTabs("wxapp_set_basic_pay",0) ) tabs.push({key:'pay',tab:'支付/提现'})
                if( this.getTabs("wxapp_set_basic_recharge",0) ) tabs.push({key:'recharge',tab:'充值设置'})
                if( this.getTabs("wxapp_set_basic_desc",0) ) tabs.push({key:'farm-desc',tab:'农场简介'})
                break  
            case "miniappSet":
                if( this.getTabs("wxapp_set_mini_basic",0) ) tabs.push({key:1,tab:'基本信息'})
                if( this.getTabs("wxapp_set_mini_pay",0) ) tabs.push({key:2,tab:'支付配置'})
                if( this.getTabs("wxapp_set_mini_service",0) ) tabs.push({key:3,tab:'微信服务商'})
                if( this.getTabs("wxapp_set_mini_subscribe_news",0) ) tabs.push({key:4,tab:'订阅消息'})
                if( this.getTabs("wxapp_set_mini_hand_publish",0) ) tabs.push({key:5,tab:'发布小程序'})
                if( this.getTabs("wxapp_set_mini_auth_publish",0) ) tabs.push({key:6,tab:'授权发布小程序'})
                break; 
            case "wxset":
                if( this.getTabs("wxapp_set_wx_basic",0) ) tabs.push({key:1,tab:'基本设置'})
                if( this.getTabs("wxapp_set_wx_pay",0) ) tabs.push({key:2,tab:'支付配置'})
                if( this.getTabs("wxapp_set_wx_message",0) ) tabs.push({key:4,tab:'订阅消息'})
                if( this.getTabs("wxapp_set_wx_user",0) ) tabs.push({key:5,tab:'绑定推送用户'})
                if( this.getTabs("wxapp_set_wx_h5",0) ) tabs.push({key:3,tab:'H5设置'})
                break;  
            case "appset":
                if( this.getTabs("wxapp_set_app_basic",0) ) tabs.push({key:1,tab:'基本配置'})
                if( this.getTabs("wxapp_set_app_update",0) ) tabs.push({key:2,tab:'更新设置'})
                if( this.getTabs("wxapp_set_app_push",0) ) tabs.push({key:5,tab:'消息推送'})
                if( this.getTabs("wxapp_set_app_wxpay",0) ) tabs.push({key:6,tab:'微信支付'})
                if( this.getTabs("wxapp_set_app_alipay",0) ) tabs.push({key:7,tab:'支付宝支付'})
                if( this.getTabs("wxapp_set_app_package",0) ) tabs.push({key:3,tab:'打包配置'})
                if( this.getTabs("wxapp_set_app_package_apply",0) ) tabs.push({key:4,tab:'打包申请'})
                break;  
            case "notice":
                if( this.getTabs("wxapp_set_notice_short",0) ) tabs.push({key:1,tab:'短信配置'})
                if( this.getTabs("wxapp_set_notice_email",0) ) tabs.push({key:2,tab:'邮件配置'})
                if( this.getTabs("wxapp_set_notice_print",0) ) tabs.push({key:3,tab:'打印机'})
                if( this.getTabs("wxapp_set_notice_service",0) ) tabs.push({key:4,tab:'客服配置'})
                if( this.getTabs("wxapp_set_notice_service_manager",0) ) tabs.push({key:5,tab:'客服管理'})
                break;  
            case "delivery":
                if( this.getTabs("wxapp_set_delivery_basic",0) ) tabs.push({key:1,tab:'基本配置'})
                if( this.getTabs("wxapp_set_delivery_freight",0) ) tabs.push({key:2,tab:'运费模板'})
                if( this.getTabs("wxapp_set_delivery_self",0) ) tabs.push({key:3,tab:'同城跑腿'})
                if( this.getTabs("wxapp_set_delivery_logistics",0) ) tabs.push({key:4,tab:'商家自送'})
                if( this.getTabs("wxapp_set_delivery_paotui",0) ) tabs.push({key:5,tab:'物流配置'})
                break;  
            case "shop-set":
                if( this.getTabs("wxapp_shop_set_basic",0) ) tabs.push({key:'shop_set_basic',tab:'基本配置'})
                if( this.getTabs("wxapp_custom_page_slide_list",0) ) tabs.push({key:'shop_set_slide',tab:'轮播图'})
                break;  
            default:
                break
        }

        return tabs
       
    }
}

const routerhandle = new routerHandle()
export default routerhandle